import shop from "../../api/shop";

// initial state
const state = () => ({
  data: {},
  token: '',
  signing: {},
  forward: {},
});

// getters
const getters = {
  token: (s) => s.token,
  data: (s) => s.data,
  providerChange: (s) =>
    s.data.provider_change !== undefined && s.data.provider_change == 1 ? 1 : 0,
};

// actions
const actions = {
  create({ state, commit }, params) {
    if (state.token) {
      commit("mergeData", params.data);
      shop.updateAccount(state.token, params.data, params.cb);
    } else {
      commit("setData", params.data);
      shop.createAccount(params.data, (result, token) => {
        commit("setToken", token);
        params.cb(result);
      });
    }
  },
  update({ state, commit }, params) {
    commit("mergeData", params.data);
    shop.updateAccount(state.token, params.data, (result) => {
      params.cb(result);
    });
  },
  signing({ state, commit }, params) {
    commit("setSigning", params.data);
    shop.signContract(state.token, params.data, params.cb);
  },
  forward({ state, commit }, params) {
    commit("setForward", params.data);
    shop.setForward(state.token, params.data, params.cb);
  },
  clearToken({ commit }) {
    commit("clearToken");
  },
  anonlogin({ commit }, params) {
    shop.anonLogin(params.data, (ok, data, token) => {
      if (ok) {
        commit("setData", data);
        commit("setToken", token);
      }
      params.cb(ok);
    });
  },
  clearUserAll({ commit }) {
    commit("clearAll");
  },
};

// mutations
const mutations = {
  setData(state, data) {
    state.data = data;
  },
  mergeData(state, data) {
    state.data = { ...state.data, ...data };
  },
  clearAll(state) {
    state.data = {};
    state.signing = {};
    state.forward = {};
  },
  setSigning(state, data) {
    state.signing = data;
  },
  setForward(state, data) {
    state.forward = data;
  },
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
