import shop from "../../api/shop";

// initial state
const state = () => ({
  all: [{ ID: 0, Name: "" }],
});

// getters
const getters = {};

// actions
const actions = {
  getAllInsurances({ commit }) {
    shop.getInsurances((ins) => {
      commit("setInsurances", ins);
    });
  },
};

// mutations
const mutations = {
  setInsurances(state, ins) {
    state.all = ins;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
