import { createApp } from "vue";
import App from "./components/App.vue";
import router from "./router";
import store from "./store";
// import { currency } from "./currency";
import "../less/mecurio.css";

const app = createApp(App);

app.use(store);
app.use(router);

app.mount("#app");
