import shop from "../../api/shop";

// initial state
const state = () => ({
  all: [],
});

// getters
const getters = {};

// actions
const actions = {
  getAllProducts({ commit }) {
    shop.getProducts((products) => {
      if (products.length === 0)
        return;

      var mainproduct = [];
      var subproduct = {};

      for (let i = 0; i < products.length; ++i) {
        let artnr = products[i].Nr.split("-");
        if (artnr.length > 1) {
          var asdf = {};
          for (const item in products[i]) {
            if (item === "Nr") {
              asdf.Nr = artnr[0];
            } else {
              asdf[item] = products[i][item];
            }
          }

          let found = false;
          for (let x = 0; x < mainproduct.length; ++x) {
            if (asdf.Nr == mainproduct[x].Nr) {
              found = true;
            }
          }
          if (found === false) {
            mainproduct.push(JSON.parse(JSON.stringify(asdf)));
          }
          if (subproduct[artnr[0]] === undefined) {
            subproduct[artnr[0]] = [];
          }

          if (artnr.length > 2) {
            let subnr = artnr[artnr.length - 2] + "-" + artnr[artnr.length - 1];
            let name = artnr[artnr.length - 1];
            for (let x = 0; x < subproduct[artnr[0]].length; ++x) {
              if (subproduct[artnr[0]][x].Name === name) {
                name = "";
                break;
              }
            }
            subproduct[artnr[0]].push({ ID: asdf.ID, Name: name, SubNr: subnr });
          } else {
            subproduct[artnr[0]].push({
              ID: asdf.ID,
              Name: artnr[artnr.length - 1],
              SubNr: artnr[artnr.length - 1],
            });
          }
        } else {
          mainproduct.push(products[i]);
        }
      }

      // sort subproducts
      for (var key in subproduct) {
        subproduct[key].sort((a, b) => b.Name.charCodeAt(0) - a.Name.charCodeAt(0));
      }

      commit("setProducts", mainproduct);
      commit("setSubProducts", subproduct);
    });
  },
};

// mutations
const mutations = {
  setProducts(state, products) {
    state.all = products;
  },
  setSubProducts(state, products) {
    state.sub = products;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
