import { createStore, createLogger } from "vuex";
import VuexPersistence from 'vuex-persist'
import cart from "./modules/cart";
import products from "./modules/products";
import insurances from "./modules/insurances";
import user from "./modules/user";

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  modules: {
    cart,
    products,
    insurances,
    user,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
