import shop from "../../api/shop";

const MAX = 33.6;
const MIN = 31.0;

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  items: [],
  checkoutStatus: null,
  sub: {},
});

// getters
const getters = {
  cartProducts: (state, getters, rootState) =>
    state.items.map(({ ID, Quantity, SubActive, SubActiveName }) => {
      const product = rootState.products.all.find((product) => product.ID === ID);
      // something is wrong with the product/cart mapping - delete cart
      if (product === undefined) {
        return {};
      }
      const subproduct = rootState.products.sub[product.Nr];
      return {
        ID: product.ID,
        Nr: product.Nr,
        Sub: subproduct,
        SubActive: SubActive,
        SubActiveName: SubActiveName,
        Title: product.Title,
        SubTitle: product.SubTitle,
        Description: product.Description,
        Price: product.Price,
        Quantity,
      };
    }),

  cartTotalPriceProgress: (state, getters) => {
    let total = getters.cartProducts.reduce((total, product) => total + product.Price * product.Quantity, 0);
    if (total >= MAX) return MAX;
    return total;
  },

  cartTotalPriceMax: (state, getters) =>
    MAX -
    getters.cartProducts.reduce((total, product) => total + product.Price * product.Quantity, 0),

  cartMinPrice: (state, getters) =>
    getters.cartProducts.reduce((total, product) => total + product.Price * product.Quantity, 0) >=
    MIN,
};

// actions
const actions = {
  checkout({ commit, state }, params) {
    const savedCartItems = [...state.items];
    commit("setCheckoutStatus", null);

    var data = {};
    data.subscriptions = params.data.map(({ ID, Quantity, SubActive }) => {
      let activeid = SubActive !== undefined ? SubActive : ID;
      return {
        isactive: true,
        productid: activeid,
        Count: Quantity,
      };
    });

    shop.saveSubcription(this.state.user.token, data, (a) => {
      if (a === true) {
        commit("setCheckoutStatus", "successful");
        params.cb(true);
      } else {
        commit("setCheckoutStatus", "failed");
        // rollback to the cart saved before sending the request
        commit("setCartItems", { items: savedCartItems });
        params.cb(false);
      }
    });
  },

  getSubscription({ state, rootState, commit }) {
    commit("clearCart");
    commit("setCheckoutStatus", null);
    shop.getSubcription(this.state.user.token, (products) => {
      let i=0;
      while (i < products.length) {
        let product = products[i];
        const cartItem = state.items.find((item) => {
          return item.ID === product.ProductID
        });
        const productItem = rootState.products.all.find((p) => p.ID === product.ProductID);
        if (!cartItem) {
          commit("pushProductToCart", { ID: product.ProductID, Nr: productItem.Nr, Sub: rootState.products.sub[productItem.Nr], Quantity: product.Count });
        } else {
          commit("incrementItemQuantity", cartItem);
        }
        i++;
      }
    });
  },

  changeSub({ commit }, params) {
    commit("setSub", params);
  },

  clearCart({ commit }) {
    commit("clearCart");
  },

  addProductToCart({ state, rootState, commit }, product) {
    commit("setCheckoutStatus", null);
    const cartItem = state.items.find((item) => item.ID === product.ID);
    if (!cartItem) {
      commit("pushProductToCart", { ID: product.ID, Nr: product.Nr, Sub: rootState.products.sub[product.Nr] });
    } else {
      commit("incrementItemQuantity", cartItem);
    }
  },

  removeProductFromCart({ state, commit }, product) {
    commit("setCheckoutStatus", null);
    const cartItem = state.items.find((item) => item.ID === product.ID);
    if (cartItem.Quantity === 1) {
      commit("popProductFromCart", { ID: product.ID });
    } else {
      commit("decrementItemQuantity", cartItem);
    }
  },
};

// mutations
const mutations = {
  pushProductToCart(state, { ID, Nr, Sub, Quantity }) {
    let subactive = undefined;
    let subActiveName = undefined;

    if (Quantity === undefined)
      Quantity = 1;

    if (Sub !== undefined) {
      let m = Sub.find((item) => item.Name === 'M')
      if (m !== undefined) {
        subactive = m.ID;
        subActiveName = m.Name;
      }
    }

    state.items.push({
      ID,
      Quantity: Quantity,
      SubActive: subactive,
      SubActiveName: subActiveName,
      Nr,
    });
  },

  clearCart(state) {
    state.items = [];
    state.checkoutStatus= null;
    state.sub= {};
  },

  popProductFromCart(state, { ID }) {
    const i = state.items.map((item) => item.ID).indexOf(ID);
    state.items.splice(i, 1);
  },

  incrementItemQuantity(state, { ID }) {
    const cartItem = state.items.find((item) => item.ID === ID);
    cartItem.Quantity++;
  },

  decrementItemQuantity(state, { ID }) {
    const cartItem = state.items.find((item) => item.ID === ID);
    cartItem.Quantity--;
  },

  setCartItems(state, { items }) {
    state.items = items;
  },

  setCheckoutStatus(state, status) {
    state.checkoutStatus = status;
  },

  setSub(state, params) {
    for (let i = 0; i < state.items.length; ++i) {
      if (state.items[i].Nr == params.nr) {
        state.items[i].SubActive = params.id;
        state.items[i].SubActiveName = params.name;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
