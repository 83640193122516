import { createRouter, createWebHashHistory } from "vue-router";
import ProductSelection from "../components/ProductSelection.vue";

const routes = [
  {
    path: "/",
    name: "ProductSelection",
    component: ProductSelection,
  },
  {
    path: "/kontaktdaten",
    name: "Kontaktdaten",
    component: () => import(/* webpackChunkName: "ContactData" */ "../components/Datenangabe.vue"),
  },
  {
    path: "/unterschrift",
    name: "Unterschrift",
    component: () => import(/* webpackChunkName: "Signing" */ "../components/Unterschrift.vue"),
  },
  {
    path: "/abschluss",
    name: "Abschluss",
    component: () => import(/* webpackChunkName: "Finish" */ "../components/Finish.vue"),
  },
  {
    path: "/fehler",
    name: "Fehler",
    component: () => import(/* webpackChunkName: "Error" */ "../components/Error.vue"),
  },
  {
    path: "/anpassen",
    name: "Anpassen",
    component: () => import(/* webpackChunkName: "Anpassen" */ "../components/AdjustProductSelection.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
