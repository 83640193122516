export default {
  //baseURL: "https://box-staging.elder-aid.de",
  baseURL: "",

  getProducts(cb) {
    fetch(this.baseURL + "/api/v1/products", {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then((response) => {
        cb(response);
      });
  },

  getInsurances(cb) {
    fetch(this.baseURL + "/api/v1/insurances", {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then((response) => {
        cb(response);
      });
  },

  downloadOrder(token, cb) {
    fetch(this.baseURL + "/api/v1/user/order/pdf", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        return null;
      })
      .then((response) => {
        cb(response);
      });
  },

  saveSubcription(token, data, cb) {
    fetch(this.baseURL + "/api/v1/user/subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return {};
      })
      .then((response) => {
        if (response.ID > 0) {
          cb(true);
        } else {
          cb(false);
        }
      });
  },

  getSubcription(token, cb) {
    fetch(this.baseURL + "/api/v1/user/subscriptions", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then((response) => {
        cb(response);
      });
  },

  createAccount(data, cb) {
    fetch(this.baseURL + "/api/v1/user/account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return {};
      })
      .then((response) => {
        if (response.token === undefined) {
          cb(false);
        } else {
          cb(true, response.token);
        }
      });
  },

  updateAccount(token, data, cb) {
    fetch(this.baseURL + "/api/v1/user/account", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        cb(true);
      } else {
        cb(false);
      }
    });
  },

  signContract(token, data, cb) {
    fetch(this.baseURL + "/api/v1/user/signing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        cb(true);
      } else {
        cb(false);
      }
    });
  },

  setForward(token, data, cb) {
    fetch(this.baseURL + "/api/v1/user/forward", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        cb(true);
      } else {
        cb(false);
      }
    });
  },

  anonLogin(data, cb) {
    fetch(this.baseURL + "/api/v1/act/" + data, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return {};
      })
      .then((response) => {
        if (response.token === undefined) {
          cb(false, {});
        } else {
          cb(true, response.data, response.token);
        }
      });
  },
};
