<!-- eslint-disable -->
<template>
    <div class="container-snap flex lg:flex-col -mr-8 lg:mr-0 p-2 space-y-0 lg:space-y-4 space-x-4 lg:space-x-0 overflow-x-auto" style="scroll-snap-type: y mandatory;">
        <button class="flex flex-col lg:flex-row flex-shrink-0 place-items-center justify-between w-2/3 sm:w-1/3 md:w-1/4 lg:w-full p-2 border border-gray-200 cursor-pointer transform hover:-translate-y-0.5 active:translate-y-0.5 shadow hover:shadow-md active:shadow-sm rounded-md disabled:shadow-none overflow-hidden"
            style="scroll-snap-align: start;"
            v-for="product in products"
            :key="product.ID"
            :class="{'bg-gray-100' : product.Price > cartTotalPriceMax, 'bg-white' : product.Price < cartTotalPriceMax}" :disabled="product.Price > cartTotalPriceMax"
            @click="addProductToCart(product)"> 
            <div class="flex flex-col lg:flex-row place-items-center w-full">
                <div 
                  class="flex-grow-0 shrink-0 p-2 rounded-lg w-full lg:w-auto"
                  :class="{'bg-gray-200' : product.Price > cartTotalPriceMax, 'bg-emerald-100' : product.Price < cartTotalPriceMax}"
                  >
                    <img class="h-14 w-14 mx-auto" :src="productImage(product.ID)" :alt="product.Title" />
                </div>
                <div class="mt-2 lg:ml-4 text-left">
                    <span class="text-lg lg:text-2xl text-emerald-700 font-medium break-words">{{ product.Title }}</span>
                    <p class="text-md lg:text-xl text-center lg:text-left text-emerald-600">{{ product.SubTitle }}</p>
                </div>
            </div>
            <div class="flex place-items-center justify-items-center mt-2 lg:m-0">
                <div class="p-2 rounded-lg">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="h-14 w-14 transform rotate-90 lg:rotate-0"
                      :class="{'text-gray-400' : product.Price > cartTotalPriceMax, 'text-emerald-600' : product.Price < cartTotalPriceMax}" :disabled="product.Price > cartTotalPriceMax">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <!--
                <div 
                    class="lg:hidden text-sm"
                    :class="{'text-gray-400' : product.Price > cartTotalPriceMax, 'text-emerald-600' : product.Price < cartTotalPriceMax}" :disabled="product.Price > cartTotalPriceMax"
                    >In den Warenkorb
                </div>
                -->
            </div>
        </button>
    </div>
    <!-- eslint-enable -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { currency } from "../currency";
import shop from "../api/shop";
//Jan: VueSimpleSVG
//import SimpleSVG from "vue-simple-svg";

export default {
  //   components: { SimpleSVG },
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters("cart", ["cartTotalPriceMax"]),
  },
  methods: {
    ...mapActions("cart", ["addProductToCart"]),
    currency,
    productImage(id) {
      return shop.baseURL + "/product/image/" + id;
    },
  },
  created() {
    this.$store.dispatch("products/getAllProducts");
  },
};
</script>
