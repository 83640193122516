const digitsRE = /(\d{3})(?=\d)/g;

export function currency(value, icurrency, decimals) {
  const vvalue = parseFloat(value);
  if (!Number.isFinite(vvalue) || (!vvalue && vvalue !== 0)) return "";
  const ccurrency = icurrency != null ? icurrency : "€";
  const ddecimals = decimals != null ? decimals : 2;
  const stringified = Math.abs(vvalue).toFixed(ddecimals);
  const intint = ddecimals ? stringified.slice(0, -1 - ddecimals) : stringified;
  const i = intint.length % 3;
  const head = i > 0 ? intint.slice(0, i) + (intint.length > 3 ? "." : "") : "";
  const ffloat = ddecimals ? stringified.slice(-1 - ddecimals) : "";
  const sign = vvalue < 0 ? "-" : "";
  return sign + ccurrency + head + intint.slice(i).replace(digitsRE, "$1.") + ffloat;
}

export default "bar";
