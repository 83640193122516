<template>
  <div class="cart p-6 bg-white rounded-lg">
    <div class="flex justify-center -mt-14">
      <div class="p-4 bg-white rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-14 w-14 text-gray-600"
          viewBox="0 0 256 256"
        >
          <path
            d="M228.3 71.8h-.1a13.4 13.4 0 0 0-5.2-5.1L135 17a14.1 14.1 0 0 0-13.8 0l-88 49.5a13.8 13.8 0 0 0-4.9 4.7l-.3.4h-.1a14.8 14.8 0 0 0-1.8 6.9v98.6a14 14 0 0 0 7.1 12.2l88 49.5a14.8 14.8 0 0 0 6.1 1.8h1.7a14.8 14.8 0 0 0 6-1.8l88-49.5a14 14 0 0 0 7.1-12.2V78.7a13.4 13.4 0 0 0-1.8-6.9zM127.1 27.4l1-.2l1 .2L211.9 74l-34.7 19.7l-84.8-46.8zm1.9 93.7L44.4 74l35.7-20.1l84.9 46.8zm-89.9 58a2.2 2.2 0 0 1-1-1.8V84.2l84.9 47.3l-.8 94.3zm178 0l-82.9 46.6l.8-94.2l36.1-20.5v41.5a6 6 0 0 0 12 0v-48.3l35-19.9v93a2.2 2.2 0 0 1-1 1.8z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <div class="flex items-baseline -mt-8">
      <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          class="bg-red-300 h-2.5 rounded-full"
          :style="{ width: progress * 3 + '%' }"
          :class="{
            'bg-red-300': progress < 25,
            'bg-green-400': progress > 25,
          }"
        ></div>
      </div>
      <div
        class="ml-6 text-5xl font-bold"
        :class="{ 'text-red-300': !minPrice, 'text-green-500': minPrice }"
      >
        40€
      </div>
    </div>
    <p class="text-right text-red-300" v-show="!minPrice">Mindestbetrag noch nicht erreicht!</p>
    <p class="text-right text-green-500" v-show="minPrice">Mindestbetrag erreicht!</p>
    <div class="mt-12 text-2xl text-center text-gray-400 font-bold" v-show="!products.length">
      Wählen Sie Pflegehilfsmittel für Ihre Box aus
    </div>

    <!-- IN CART -->
    <div
      class="
        flex 
        flex-row
        flex-shrink-0 
        flex-wrap
        place-items-center
        justify-between
        w-full
        mt-4
        p-2
        bg-white
        border border-gray-200
        shadow
        rounded-lg
        overflow-hidden
      "
      v-for="product in products"
      :key="product.ID"
    >
		<div class="flex flex-row place-items-center justify-between w-full">
			<div class="p-2 bg-emerald-200 rounded-lg">
				<img class="h-8 w-8 lg:h-14 lg:w-14 mx-auto" :src="productImage(product.ID)" :alt="product.Title" />
			</div>
			<div class="flex mt-2 ml-4 text-left">
				<div
					class="text-lg lg:text-2xl text-gray-400 mr-2"
					v-bind:class="{ hidden: !(product.Quantity > 1) }"
				>
					{{ product.Quantity }} &times;
				</div>
				<div>
					<div class="text-sm sm:text-lg lg:text-2xl text-gray-600 font-medium break-words">{{ product.Title }}</div>
					<div class="text-sm sm:text-md lg:text-xl text-left text-gray-500">
						{{ product.SubTitle }}
					</div>
				</div>
			</div>
			<div class="flex place-items-center justify-items-center p-2 rounded-full cursor-pointer">
				<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-10 w-10 text-red-300"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				:disabled="product.Price > total"
				@click="removeProductFromCart(product)"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
			</div>
		</div>

      <div class="w-full bg-gray-100 mt-2 p-4" :class="{ hidden: product.Sub == undefined }">
        <div class="flex flex-col items-center justify-center">
          <div class="text-gray-600 font-semibold">Größe wählen</div>
          <div class="mt-2">
            <div class="inline-flex rounded-md shadow-sm text-gray-600" role="group">
              <label
                class="py-2 px-4 text-sm font-semibold cursor-pointer"
                type="button"
                v-for="(sub) in product.Sub"
                :key="sub.ID"
                v-show="sub.Name !== ''"
                :class="{
                  'rounded-l-lg border border-gray-200 i0': sub.Name === 'S',
                  'border-t border-b border-gray-200 i1': sub.Name === 'M',
                  'rounded-r-lg border border-gray-200 i5': sub.Name == 'L',
                  'bg-emerald-400 text-emerald-800': product.SubActiveName == sub.Name,
                  'bg-white': product.SubActiveName !== sub.Name,
                }"
              >
                <input
                  type="radio"
                  :name="product.Nr"
                  :value="sub.Name"
                  style="display:none"
                  @change="changeSub"
                />
                {{ sub.Name }}
              </label>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <div class="text-gray-600 font-semibold">
            Allergie oder Unverträglichkeit angeben (optional)
          </div>
          <div class="mt-2">
            <div class="inline-flex rounded-md shadow-sm bg-white" role="group">
              <label
                class="
                  py-2
                  px-4
                  text-sm
                  font-semibold
                  rounded-l-lg
                  border border-gray-200
                  cursor-pointer
                "
                :class="{
                  'bg-white text-gray-600 hover:bg-red-100': subAllergy.latex == false,
                  'bg-red-400 text-white': subAllergy.latex == true,
                }"
              >
                <input
                  type="checkbox"
                  :name="'X' + product.Nr"
                  style="display: none"
                  v-model="subAllergy.latex"
                  @change="changeSubAllergy"
                />
                Latex
              </label>
              <label
                class="
                  py-2
                  px-4
                  text-sm
                  font-semibold
                  border-t border-b border-gray-200
                  cursor-pointer
                "
                :class="{
                  'bg-white text-gray-600 hover:bg-red-100': subAllergy.vinyl == false,
                  'bg-red-400 text-white': subAllergy.vinyl == true,
                }"
              >
                <input
                  type="checkbox"
                  :name="'Y' + product.Nr"
                  style="display: none"
                  v-model="subAllergy.vinyl"
                  @change="changeSubAllergy"
                />
                Vinyl
              </label>
              <label
                class="
                  py-2
                  px-4
                  text-sm
                  font-semibold
                  rounded-r-lg
                  border border-gray-200
                  cursor-pointer
                "
                :class="{
                  'bg-white text-gray-600 hover:bg-red-100': subAllergy.nitril == false,
                  'bg-red-400 text-white': subAllergy.nitril == true,
                }"
              >
                <input
                  type="checkbox"
                  :name="'Z' + product.Nr"
                  style="display: none"
                  v-model="subAllergy.nitril"
                  @change="changeSubAllergy"
                />
                Nitril
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /CART -->
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { currency } from "../currency";
import shop from "../api/shop";

export default {
  props: ["checkSub"],
  data: () => ({
    subSelect: {},
    allergy: "V",
    sizesize: "",
    subAllergy: { latex: false, vinyl: false, nitril: false },
  }),
  computed: {
    ...mapState({
      checkoutStatus: (state) => state.cart.checkoutStatus,
    }),
    ...mapGetters("cart", {
      products: "cartProducts",
      total: "cartTotalPriceMax",
      progress: "cartTotalPriceProgress",
      minPrice: "cartMinPrice",
    }),
  },
  methods: {
    ...mapActions("cart", ["removeProductFromCart"]),
    currency,
    changeSubAllergy(bla) {
      this.allergy = "V";
      if (this.subAllergy.vinyl === true) {
        this.allergy = "N";
      }
      if (this.subAllergy.nitril === true) {
        this.allergy = "L";
      }
      if (this.sizesize.length !== 0) {
        this.changeSubX(bla.target.name.substring(1), this.sizesize);
      }
    },
    changeSub(e) {
      this.changeSubX(e.target.name, e.target.value);
    },
    changeSubX(name, value) {
      var searchSubNr = this.allergy + "-" + value;

      var searchID = 0;
      for (var i = 0; i < this.products.length; ++i) {
        if (this.products[i].Nr === name) {
          for (var s = 0; s < this.products[i].Sub.length; ++s) {
            if (this.products[i].Sub[s].SubNr === searchSubNr) {
              searchID = this.products[i].Sub[s].ID;
              break;
            }
          }
          break;
        }
      }

      this.$store.dispatch("cart/changeSub", { nr: name, id: searchID, name: value });
    },
    productImage(id) {
      return shop.baseURL + "/product/image/" + id;
    },
  },
};
</script>
