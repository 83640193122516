<!-- eslint-disable -->
<template>
  <div class="grid grid-cols-4 mb-12">
    <div class="relative flex flex-col items-center">
      <div
        class="h-6 text-sm lg:text-xl text-center"
        :class="{ 'text-green-500': state > 1, 'text-yellow-400 font-bold': state == 1 }"
      >
        Box wählen
      </div>
      <div
        class="flex items-center justify-center mt-4 font-bold text-xl bg-yellow-300 rounded-full w-12 h-12 shadow"
        :class="{ hidden: state > 1 }"
      >
        1
      </div>
      <div
        class="flex items-center justify-center mt-4 bg-green-200 rounded-full w-12 h-12"
        :class="{ hidden: state == 1 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <div class="absolute w-1/2 top-16 left-3/4 border border-gray-300 border-dashed"></div>
    </div>

    <div class="relative flex flex-col items-center">
      <div
        class="h-6 text-sm lg:text-xl text-center"
        :class="{
          'text-gray-400': state < 2,
          'text-yellow-400 font-bold': state == 2,
          'text-green-500': state > 2,
        }"
      >
        Daten angeben
      </div>
      <div
        class="
          flex
          items-center
          justify-center
          mt-4
          font-bold
          text-xl text-gray-400
          bg-gray-200
          rounded-full
          w-12
          h-12
        "
        :class="{ hidden: state > 2, 'bg-yellow-300 text-yellow-700 shadow-md': state == 2 }"
      >
        2
      </div>
      <div
        class="flex items-center justify-center mt-4 bg-green-200 rounded-full w-12 h-12"
        :class="{ hidden: state <= 2 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <div class="absolute w-1/2 top-16 left-3/4 border border-gray-300 border-dashed"></div>
    </div>

    <div class="relative flex flex-col items-center">
      <div
        class="h-6 text-sm lg:text-xl text-center"
        :class="{
          'text-gray-400': state < 3,
          'text-yellow-400 font-bold': state == 3,
          'text-green-500': state > 3,
        }"
      >
        Unterschrift
      </div>
      <div
        class="
          flex
          items-center
          justify-center
          mt-4
          font-bold
          text-xl text-gray-400
          bg-gray-200
          rounded-full
          w-12
          h-12
        "
        :class="{ hidden: state > 3, 'bg-yellow-300 text-yellow-700 shadow-md': state == 3 }"
      >
        3
      </div>
      <div
        class="flex items-center justify-center mt-4 bg-green-200 rounded-full w-12 h-12"
        :class="{ hidden: state <= 3 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <div class="absolute w-1/2 top-16 left-3/4 border border-gray-300 border-dashed"></div>
    </div>

    <div class="relative flex flex-col items-center">
      <div
        class="h-6 text-sm lg:text-xl text-center"
        :class="{
          'text-gray-400': state < 4,
          'text-yellow-400 font-bold': state == 4,
          'text-green-500': state > 4,
        }"
      >
        Fertig
      </div>
      <div
        class="
          flex
          items-center
          justify-center
          mt-4
          font-bold
          text-xl text-gray-400
          bg-gray-200
          rounded-full
          w-12
          h-12
        "
        :class="{ hidden: state > 4, 'bg-yellow-300 text-yellow-700 shadow-md': state == 4 }"
      >
        4
      </div>
      <div
        class="flex items-center justify-center mt-4 bg-green-200 rounded-full w-12 h-12"
        :class="{ hidden: state <= 4 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import {} from "vuex";

export default {
  props: ["state"],
  data: () => ({}),
};
</script>
